.bgCardYourOnline{
    background-image: url("/src/img/BgOpacityOnline.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 100% 45px;
}

.bgCardYourOnline .borderGrey{
    content: "";
    background-color: var(--light-grey);
    height: 100%;
    width: 4px;
    border-radius: 20px;
}