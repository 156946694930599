#targetWizard {
    min-height: calc(100vh - 54px);
}

#targetWizardContent .gradient-input-round, #targetWizardContent .gradient-input {
    max-width: 312px;
}

/* SELECT TREE dropdown */

.gradient-dropdowntree span.e-input-group.e-control-wrapper {
    flex-direction: row-reverse;
    display: flex;
}

input#dropdowntree, #targetWizardContent input#dropdownlist {
    cursor: pointer;
    width: 100%;
    flex-direction: row-reverse;
    display: flex;
    border: none;
    height: 30px;
    border-radius: 40px;
    padding-left: 10px;
    font-size: 16px;
}

select#dropdowntree_hidden, select#dropdownlist_hidden {
    display: none;
}

.e-treeview .e-list-item>.e-text-content .e-list-text {
    width: 100%;
}

/* ARROW GRAD */

.gradient-dropdowntree span.e-input-group.e-control-wrapper {
    position: relative;
}

.storeIconTree {
    background-image: url("/src/img/IconGreyStore.png");
    background-repeat: no-repeat;
    height: 18px;
    width: 18px;
    background-repeat: none;
    background-size: contain;
}

.gradient-dropdowntree span.e-input-group.e-control-wrapper:after, .gradient-dropdowntree span.e-input-group.e-control-wrapper:after {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    right: 10px;
    top: 12px;
    background-image: url(/src/img/IconGradSelectArrow.png);
    background-repeat: no-repeat;
    background-size: contain;
}

.e-input-group.e-ddl #dropdownlist.e-input {
    background: var(--white);
}

.e-input-group.e-ddl #dropdownlist.e-input.e-disabled, span[aria-labelledby='dropdownlist_hidden'] {
    opacity: 1;
}

div#dropdowntree_popup {
    margin-top: 1px;
    background-color: var(--white);
    display: flex;
    border-radius: 14px;
    box-shadow: 0 3px 16px #16222c0f;
}

.e-popup-content.e-dropdown {
    width: 100%;
    min-height: 80px;
    overflow: auto;
}

/* SCROLLBAR */

.e-popup-content.e-dropdown::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

/* Track */

.e-popup-content.e-dropdown::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

/* Handle */

.e-popup-content.e-dropdown::-webkit-scrollbar-thumb {
    background: rgb(199, 199, 199);
    border-radius: 10px;
}

/* SELECT TREE dropdown END */

/* SELECT KPI dropdown  */

div#dropdownlist_popup {
    overflow: auto;
    background-color: var(--white);
    border-radius: 24px;
    box-shadow: 0 3px 16px #16222c0f;
    padding: 10px 0;
}

ul#dropdownlist_options {
    padding: 0;
}

div#dropdownlist_popup li {
    color: var(--black);
    font-size: 14px;
    padding:8px 16px;
    cursor: pointer;
}

div#dropdownlist_popup li:hover {
    background-color: var(--light-grey);
}

/* SELECT KPI dropdown END */

/* DATEPICKER */

/* Select Duration (Radio box) */

#targetWizardContent #dateInputGroup .stv-radio-buttons-wrapper {
    padding: 1px 1px 1px 0;
    width: max-content;
    border-radius: 30px;
    background: linear-gradient(to left, #B65AFD 0%, #70B3ED 50%, #4CDEB1 100%);
}

#targetWizardContent #dateInputGroup .stv-radio-button+label {
    padding: .25em 1em;
    color: var(--black);
    background: var(--white);
    margin: 0 1px;
    border: none;
}

#targetWizardContent #dateInputGroup .stv-radio-buttons-wrapper .buttonRadio:first-child label {
    border-bottom-left-radius: 40px;
    border-top-left-radius: 40px;
}

#targetWizardContent #dateInputGroup .stv-radio-buttons-wrapper .buttonRadio:last-child label {
    border-bottom-right-radius: 40px;
    border-top-right-radius: 40px;
}

#targetWizardContent #dateInputGroup .stv-radio-button:checked+label {
    font-size: 14px;
    background: transparent;
    color: var(--white);
}

#targetWizardContent #dateInputGroup .stv-radio-buttons-wrapper .buttonRadio {
    background: transparent;
    border: none;
}

/* Select DATE (CALENDAR) */

#targetWizardContent .gradient-calendar {
    height: fit-content;
    max-width: fit-content;
    padding: 1px;
    border-radius: 80px;
    background: linear-gradient(to left, #B65AFD 0%, #70B3ED 50%, #4CDEB1 100%);
}

#targetWizardContent .gradient-calendar .e-datepicker {
    opacity: 1;
    font-size: 14px;
    height: 30px;
    padding: 0 8px;
    min-width: 250px;
    background-color: var(--white);
}

#targetWizardContent .gradient-calendar span.e-input-group-icon.e-date-icon.e-icons {
    background-color: var(--white);
    height: 30px;
}

#targetWizardContent #datePicker .e-input-group-icon.e-date-icon::before, #targetWizardContent #DatePicker .e-control-wrapper .e-input-group-icon.e-date-icon::before {
    background-image: url('/src/img/IconGradCalendar.png');
    height: 25px;
    width: 25px;
}

#targetWizardContent #datePicker .e-disabled {
    opacity: 1;
}

/* ALERT WARNING DATE DISABLED */

#disabledDatelert .alert.targetDateDisabled {
    box-shadow: 0 2px 10px #00000029;
    width: 320px;
    border-radius: 12px;
}


.e-calendar .e-content td.e-disabled, .e-bigger.e-small .e-calendar .e-content td.e-disabled {
    pointer-events: all;
    cursor: pointer;
}

/* BUTTON SAVE WIDTH */

#targetWizardContent .gradientButton {
    width: 280px;
}

/* INPUT MOBILE WIDTH */

@media screen and (max-width: 576px) {
    #targetWizardContent .gradient-input-round, #targetWizardContent .gradient-input {
        max-width: 100%;
    }
    #targetWizardContent .gradient-calendar .e-datepicker {
        min-width: calc(100% - 30px);
    }
    #targetWizardContent .gradient-calendar {
        max-width: max-content;
        min-width: 100%;
    }
    #targetWizardContent .gradientButton {
        width: 100%;
    }
    #disabledDatelert {
        margin: auto;
    }
}