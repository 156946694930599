.miniCardRealTime {
    width: calc(50% - .5rem);
    margin: .25rem;
}

.dropDownRealtimeFilter .dropdown-menu.show {
    inset: 0px 0px auto auto !important;
}

#CardRealTime .dropdown-toggle#dropdown-filter h3::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

/* PHYSIC */

#CardRealTime .headerRealTime.bgPhysical {
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('/src/img/IconActiveStore.png');
}

.bg-extended #CardRealTime .headerRealTime.bgPhysical {
    background-image: url('/src/img/IconRealtimeStore.png');
}

@media screen and (max-width: 576px) {
    .miniCardRealTime {
        width: calc(100% - .5rem);
        margin: .25rem;
    }
    .bg-extended #CardRealTime .headerRealTime.bgPhysical {
        background-image: url('/src/img/IconActiveStore.png');
    }
}

/* ONLINE */

#CardRealTime .headerRealTime.bgOnline {
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('/src/img/IconActiveOnline.png');
}

.bg-extended #CardRealTime .headerRealTime.bgOnline {
    background-image: url('/src/img/IconRealtimeOnline.png');
}

@media screen and (max-width: 990px) {
    .miniCardRealTime {
        width: calc(100% - .5rem);
        margin: .25rem;
    }
    .bg-extended #CardRealTime .headerRealTime.bgOnline {
        background-image: url('/src/img/IconActiveOnline.png');
    }
}