.bgMailChanged{
    background-image: url("../../img//bgMailChanged.png");
    height: 35vh;
    background-position: center bottom;
    background-size: cover;
    background-repeat: no-repeat;
}

#mailChanged{
    min-height: 100vh;
}


  @media screen and (max-width: 1200px) {
    .bgMailChanged{
        height: 30vh;
    }
  }

  @media screen and (max-width: 992px) {
    .bgMailChanged{
        height: 25vh;
    }
  }

  @media screen and (max-width: 768px) {
    .bgMailChanged{
        height: 20vh;
    }
  }

  @media screen and (max-width: 576px) {
    .bgMailChanged{
        height: 15vh;
    }
  }
