#mainTabSingleShop.e-tab .e-tab-header .e-toolbar-item .e-tab-text {
  height: 100%;
  text-transform: none;
}



#mainTabSingleShop.e-tab .e-tab-header .e-toolbar-item #mainTabSingleShop.e-tab-wrap:hover {
  background: transparent;
}

#mainTabSingleShop .e-items.e-toolbar-items {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
}

#mainTabSingleShop.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap {
  height: 100%;
  padding: 0;
  background: none;
}

#mainTabSingleShop.e-tab .e-tab-header .e-indicator {
  background: none;
}

#mainTabSingleShop.e-tab .e-tab-header .e-toolbar-item .e-text-wrap {
  height: 100%;
}

#mainTabSingleShop.e-tab .e-tab-header .e-toolbar-item.e-active .e-text-wrap {
  height: 100%;
}


#mainTabSingleShop.e-tab .e-tab-header .e-toolbar-item:not(.e-separator) {
  height: 100%;
  min-height: auto;
}

/* ARROWS */

#mainTabSingleShop.e-tab .e-tab-header .e-scroll-nav.e-scroll-left-nav, #mainTabSingleShop.e-tab .e-tab-header .e-scroll-nav.e-scroll-right-nav {
  padding: 0;
}

#mainTabSingleShop.e-tab .e-tab-header .e-toolbar-items.e-hscroll .e-scroll-nav {
  width: 1.5rem;
}

#mainTabSingleShop.e-tab .e-tab-header .e-scroll-nav, .e-tab .e-tab-header .e-hscroll:not(.e-scroll-device) .e-scroll-nav {
  height: 100%;
}

#mainTabSingleShop.e-tab .e-tab-header .e-scroll-nav .e-nav-left-arrow::before, #mainTabSingleShop.e-tab .e-tab-header .e-scroll-nav .e-nav-right-arrow::before {
  font-size: 21px;
  z-index: 10;
}

.e-scroll-left-nav.e-overlay.e-lib.e-touch {
  z-index: 10;
}

.e-hscroll:not(.e-scroll-device) {
  padding: 0;
}

/* BUTTON TAB  */

/* TAB HEADER BUTTON */

#mainTabSingleShop .e-toolbar-item.e-active .headerTabButton h1 {
  font-weight: 500;
}

/*TRANSITION */

#mainTabSingleShop .headerTabButton h1, #mainTabSingleShop .headerTabButton h3, #mainTabSingleShop .headerTabButton h4, #mainTabSingleShop .headerTabButton p {
  transition: all 0.5s;
}

#mainTabSingleShop .e-toolbar-item.e-active .headerTabButton h1, #mainTabSingleShop .e-toolbar-item.e-active .headerTabButton h3, #mainTabSingleShop .e-toolbar-item.e-active .headerTabButton h4, #mainTabSingleShop .e-toolbar-item.e-active .headerTabButton p, #mainTabSingleShop .e-toolbar-item.e-active .headerTabButton:hover h1, #mainTabSingleShop .e-toolbar-item.e-active .headerTabButton:hover h3, #mainTabSingleShop .e-toolbar-item.e-active .headerTabButton:hover h4, #mainTabSingleShop .e-toolbar-item.e-active .headerTabButton:hover p {
  color: #fff;
  transition: all 0.5s;
  opacity: 1;
}

#mainTabSingleShop .headerTabButton:hover h1, #mainTabSingleShop .headerTabButton:hover h3, #mainTabSingleShop .headerTabButton:hover h4, #mainTabSingleShop .headerTabButton:hover p {
  color: #fff;
  opacity: 0.75;
  transition: all 0.5s;
}

#mainTabSingleShop .headerTabButton span {
  background-position: center;
  height: 30px;
  width: 30px;
  min-width: 30px;
  display: block;
  background-repeat: no-repeat;
  background-size: contain;
  transition: all 0.3s;
}

#mainTabSingleShop .borderGrey::before {
  display: flex;
  margin: 20px auto 10px auto;
  content: "";
  width: 80%;
  background-color: #c0c3d166;
  height: 5px;
  border-radius: 30px;
}

/* STORE */