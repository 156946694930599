.alert {
    border-radius: 10px;
}

.alert.error {
    border: 1px solid var(--redError);
    background-color: var(--white);
}

.alert.error h3 {
    color: var(--redError);
}

.alert.success {
    border: 1px solid var(--greenSuccess);
}

.alert.success h3 {
    color: var(--greenSuccess);
}

.alert.warning {
    border: 1px solid var(--yellowWarning);
    background-color: var(--white);
}

.alert.targetDateDisabled h3 {
    color: var(--yellowWarning);
}


.alert.targetDateDisabled {
    border: 1px solid var(--yellowWarning);
    background-color: var(--white);
}

.alert.targetDateDisabled h3 {
    color: var(--yellowWarning);
}