
#TabLineChartMultiShop #TabLineChartMultiShop_svg text{
  font-family: 'Lato' !important;
}


.linechartTooltipTemplate{
    min-width: 80px;
    border: 2px solid var(--light-grey);
}

.linechartTooltipTemplate .icon-trendUp,.linechartTooltipTemplate .icon-trendDown,.linechartTooltipTemplate .icon-trendDraw{
    width: 6px;
    height: auto;
    display: block;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}

.linechartTooltipTemplate  span.icon-trendUp{
    background-image: url('/src/img/IconWhiteTrendUp.png');
    opacity: .8;

  }
  
  .linechartTooltipTemplate span.icon-trendDraw{
    background-image: url('/src/img/IconGreyTrendNo.png');
    opacity: .8;

  }
  
  .linechartTooltipTemplate span.icon-trendDown {
    background-image: url('/src/img/IconWhiteTrendDown.png');
    opacity: .8;

  }