#TargetCardOverview .targetCard .circleName-s{
    border-radius: 100%;
  background: var(--medium-grey);
    height: 24px;
    width: 24px;
    min-width: 24px;
    overflow: hidden;
}

#TargetCardOverview .progressGrey .progress-bar{
background-color: var(--medium-grey);
}


#TargetCardOverview .progressTiffany .progress-bar{
  background: var(--tiffany);
}

#TargetCardOverview .progressPurple .progress-bar{
  background: var(--purple);
}

#TargetCardOverview  .circleName-grad-s{
    border-radius: 100%;
    background: linear-gradient(45deg, #18E677, #2BE090, #3EDAA9, #51D4C2, #63CEDB, #76C8F4, #85B8FE, #91A0FE, #9D88FE, #A970FD, #B458FD);
    height: 24px;
    width: 24px;
    overflow: hidden;

}