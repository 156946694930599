.e-treeview .e-ul{
   padding: 0 0 0 6px;
}
.e-treeview .e-list-item .e-ul{
   padding: 0 0 0 12px;
}

/* FILTER DESKTOP */
#filterTarget .checkBoxDropDown .gradient-input-round{
   min-width: 120px;
}

#filterTarget .dropdown-toggle {
   padding: 0;
}
#filterTarget  .listFilter div{
margin-right: 10px;
}
#filterTarget  .checkBoxDropDown:last-child  div{
   margin-right: 0;
   }
/* FILTER TAG LABEL */
.labelTagFilter{
   height: fit-content;
   border-radius: 60px;
   background-color: var(--medium-grey);
}

.labelTagFilter button,
.labelTagFilter .e-btn:active{
   color: var(--white);
   background: none;
   box-shadow: none;
}

.labelTagFilter button:hover,
.labelTagFilter .e-css.e-btn:active,
.labelTagFilter button:focus:not(:focus-visible){
   color: var(--black);
   background: none;
   box-shadow: none;}
.e-close:before{
   content:'\e7fc';
}

/* FILTER TAG TREEVIEW */
.gradient-dropdowntree-check span.e-input-group.e-control-wrapper {
   flex-direction: row-reverse;
   display: flex;
}

input#dropdowntreeFilter,
input#dropdownlist{
   cursor: pointer;
   width: 250px;
   flex-direction: row-reverse;
   display: flex;
   border: none;
   height: 30px;
   border-radius:40px;
   padding-left: 10px;
   font-size: 16px;
}

select#dropdowntreeFilter_hidden,
select#dropdowntreeFilter_hidden {
   display: none; 
}

.e-treeview .e-list-item > .e-text-content .e-list-text{
   width: 100%;
}



/* ARROW GRAD */
.gradient-dropdowntree-check span.e-input-group.e-control-wrapper{
   position: relative;
}




.gradient-dropdowntree-check span.e-input-group.e-control-wrapper:after,
.gradient-dropdowntree-check span.e-input-group.e-control-wrapper:after {
   content: '';
   position: absolute;
   width: 14px;
   height: 14px;
   right: 10px;
   top: 12px;
   background-image: url(/src/img/IconGradSelectArrow.png);
   background-repeat: no-repeat;
   background-size: contain;
}


.e-input-group.e-ddl #dropdownlist.e-input {
   background: var(--white);
}

.e-input-group.e-ddl #dropdownlist.e-input.e-disabled,
span[aria-labelledby='dropdownlist_hidden']{
   opacity: 1;
}


div#dropdowntreeFilter_popup{
background-color: var(--white);
display: flex;
border-radius: 14px;
box-shadow: 0 3px 16px #16222c0f;

}
#dropdowntreeFilter_popup .e-popup-content.e-dropdown{
   width: 100%;
   min-height: 80px;
overflow:auto;
}



/* SCROLLBAR */
#dropdowntreeFilter_popup .e-popup-content.e-dropdown::-webkit-scrollbar {
   width: 7px;
   height: 7px;
 }
 
 /* Track */
 #dropdowntreeFilter_popup .e-popup-content.e-dropdown::-webkit-scrollbar-track {
   background: #f1f1f1; 
   border-radius: 10px;
 }
  
 /* Handle */
 #dropdowntreeFilter_popup .e-popup-content.e-dropdown::-webkit-scrollbar-thumb {
   background: rgb(199, 199, 199); 
   border-radius: 10px;
 }




/* FILTER TAG TREEVIEW */

/* FILTER DESKTOP End*/
/* FILTER MOBILE */

/*BUTTON OPEN MODAL */
button.openModalFilter{
   text-transform: initial;
   height: fit-content;
   border-radius: 60px;
   background-color: var(--medium-grey);
}

button.openModalFilter,
button.openModalFilter.e-btn:active{
   color: var(--white);
   box-shadow: none;
}

button.openModalFilter:hover,
button.openModalFilter.e-css.e-btn:active,
button.openModalFilter:focus:not(:focus-visible){
   background-color: var(--dark-grey);
   color: var(--white);
   box-shadow: none;}

.e-Filter:before{
   content:'\ea77';
}


#filterTargetModal .modal-header{
border-bottom: none;
}
#filterTargetModal .accordion-item{
   border: none;
}

#filterTargetModal .accordion-item .accordion-header:after {
   content: "";
   width: 100%;
   height: 5px;
   display: block;
   border-radius: 10px;
   background: -webkit-linear-gradient(180deg, #B65AFD 0%, #70B3ED 50%,#4CDEB1 100%);
   opacity:50%;
 }
 #filterTargetModal .accordion-button{
    padding: 15px 0 10px;
   color: var(--dark-grey);
}

 #filterTargetModal .accordion-button:not(.collapsed) {
   color: var(--dark-grey);
   background-color: var(--white);
   box-shadow:none;
}

/* FILTER MOBILE END*/

