#ComparisonLineChart #ComparisonLineChart_svg text{
    font-family: 'Lato' !important;

}
#ComparisonLineChart #ComparisonLineChart_tooltip_text{
    font-family: 'Lato' !important;
    font-size: 15px !important;
}
.legendComparisonStore{
    background-color: var(--tiffany);
    border-radius: 100%;
    height: 15px;
    width: 15px;
}

.legendComparisonOnline{
    background-color: var(--purple);
    border-radius: 100%;
    height: 15px;
    width: 15px;
}

/* TOOLTIP */
.ComparisonLineChart .e-tooltip g path,#CardMySite .e-tooltip g path{
    opacity: 1;
}
