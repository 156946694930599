@media screen and (max-width: 769px) {
    #table-online.it td:nth-of-type(1):before {
      content: "Sito";
    }
    #table-online.it td:nth-of-type(2):before {
      content: "Fatturato";
    }
    #table-online.it td:nth-of-type(3):before {
      content: "Transazioni";
    }
    #table-online.it td:nth-of-type(4):before {
      content: "Transazione media";
    }
  
    #table-online.fr td:nth-of-type(1):before {
        content: "Site";
    }
    #table-online.fr td:nth-of-type(2):before {
        content: "Chiffre d'affaires";
    }
    #table-online.fr td:nth-of-type(3):before {
        content: "Transactions";
    }
    #table-online.fr td:nth-of-type(4):before {
        content: "Transaction moyenne ";
    }
}