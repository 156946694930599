.btn-group-kpi-singleshop .target-progress, .btn-group-kpi-singleshop .target-complete, .btn-group-kpi-singleshop .target-fail {
  padding: 5px;
}

.btn-group-kpi-singleshop .target-progress {
  background-color: var(--orange);
}

.btn-group-kpi-singleshop .target-none {
  background-color: var(--white);
}

.btn-group-kpi-singleshop .target-none .state {
  display: none !important;
}

.btn-group-kpi-singleshop .target-fail {
  background-color: var(--red);
}

.btn-group-kpi-singleshop .target-complete {
  background-color: var(--green);
}

.btn-group-kpi-singleshop .header {
  white-space: initial;
}

.minHeight {
  min-height: 135px;
}

@media (max-width: 1279px) {
  .btn-group-kpi-singleshop .header {
    width: 180px;
  }
}

.btn-kpi-singleshop, .btn-kpi-singleshop:hover {
  text-transform: none;
  box-shadow: none;
}

.btn-kpi-singleshop .physical span.iconCash {
  background-image: url('/src/img/IconTiffanyBigStore.png');
}

.btn-kpi-singleshop.active .physical {
  background: -webkit-linear-gradient( 270deg, #98e2d9 0%, #5dc6d0 100%) !important;
  transition: all 0.5s;
}

.btn-kpi-singleshop:hover .physical {
  background: #5dc6d0 !important;
  transition: all 0.5s;
}

.btn-kpi-singleshop.active .physical span.iconCash, .btn-kpi-singleshop:hover .physical span.iconCash {
  background-image: url('/src/img/IconWhiteBigStore.png');
  transition: all 0.3s;
}

/* ONLINE */

.btn-kpi-singleshop .online span.iconCash {
  background-image: url('/src/img/IconPurpleSession.png');
}

.btn-kpi-singleshop.active .online {
  background: -webkit-linear-gradient( 270deg, #da90ff 0%, #b558fd 100%) !important;
  transition: all 0.5s;
}

.btn-kpi-singleshop:hover .online {
  background: #b558fd !important;
  transition: all 0.5s;
}

.btn-kpi-singleshop.active .online span.iconCash, .btn-kpi-singleshop:hover .online span.iconCash {
  background-image: url('/src/img/IconWhiteSession.png');
  transition: all 0.3s;
}

.btn-kpi-singleshop .header h1, .btn-kpi-singleshop .header h3, .btn-kpi-singleshop .header h4, .btn-kpi-singleshop .header p {
  transition: all 0.5s;
}

.btn-kpi-singleshop.active .header h1, .btn-kpi-singleshop.active .header h3, .btn-kpi-singleshop.active .header h4, .btn-kpi-singleshop.active .header p {
  color: #fff;
  transition: all 0.5s;
  opacity: 1;
}

.btn-kpi-singleshop:hover .header h1, .btn-kpi-singleshop:hover .header h3, .btn-kpi-singleshop:hover .header h4, .btn-kpi-singleshop:hover .header p {
  color: #fff;
  opacity: 0.75;
  transition: all 0.5s;
}

.btn-kpi-singleshop .header span.iconCash {
  background-position: right;
  height: 20px;
  width: 20px;
  min-width: 30px;
  display: block;
  background-repeat: no-repeat;
  background-size: contain;
  transition: all 0.3s;
}

.btn-group-kpi-multishop .btn-kpi-singleshop {
  margin: 0 4px 12px 4px;
  vertical-align: bottom;
}

.btn-group-kpi-multishop .btn-kpi-singleshop:nth-child(1) {
  margin-left: 0;
}

.btn-group-kpi-multishop .btn-kpi-singleshop:last-child {
  margin-right: 0;
}

.btn-kpi-singleshop .icon-trendDown, .btn-kpi-singleshop .icon-trendUp, .btn-kpi-singleshop .icon-trendDraw {
  width: 10px;
  height: auto;
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.btn-kpi-singleshop span.icon-trendUp {
  background-image: url('/src/img/IconGreenTrendUp.png');
}

.btn-kpi-singleshop span.icon-trendDraw {
  background-image: url('/src/img/IconGreyTrendNo.png');
}

.btn-kpi-singleshop span.icon-trendDown {
  background-image: url('/src/img/IconRedTrendDown.png');
}

.btn-kpi-singleshop.active span.icon-trendUp, .btn-kpi-singleshop:hover span.icon-trendUp {
  background-image: url('/src/img/IconWhiteTrendUp.png');
  opacity: .8;
  transition: all 0.3s;
}

.btn-kpi-singleshop.active span.icon-trendDraw, .btn-kpi-singleshop:hover span.icon-trendDraw {
  background-image: url('/src/img/IconGreyTrendNo.png');
  opacity: .8;
  transition: all 0.3s;
}

.btn-kpi-singleshop.active span.icon-trendDown, .btn-kpi-singleshop:hover span.icon-trendDown {
  background-image: url('/src/img/IconWhiteTrendDown.png');
  opacity: .8;
  transition: all 0.3s;
}

/*BLOCK hover persistence on mobile*/
/* ONLINE */


/*BLOCK hover persistence on mobile*/

/* @media (hover:none) {

  
  .btn-kpi-multishop:hover div.physical span.iconCash {
    background-image: url('/src/img/IconTiffanyStore.png') !important;
  }
  .btn-kpi-multishop:hover .physical {
    background-color: var(--white) !important;
  }
  .btn-kpi-multishop:hover div.online span.iconCash {
    background-image: url('/src/img/IconPurpleSession.png') !important;
  }
  .btn-kpi-multishop:hover .online {
    background-color: var(--white) !important;
  }
  .btn-kpi-multishop:hover .header h1, .btn-kpi-multishop:hover .header h2, .btn-kpi-multishop:hover .header h3, .btn-kpi-multishop:hover .header p {

    color: inherit !important;
    opacity: 1;
  }
  .btn-kpi-multishop:hover .header h4.c-red {
 
    color: var(--red) !important;
    opacity: 1;
  }
  .btn-kpi-multishop:hover .header h4.c-green {

    color: var(--green) !important;
    opacity: 1;
  }
  .btn-kpi-multishop:hover .header h4.c-medium-grey {
  
    color: var(--medium-grey) !important;
    opacity: 1;
  }
  .btn-kpi-multishop:hover span.icon-trendUp {
    background-image: url('/src/img/IconGreenTrendUp.png');
  }
  .btn-kpi-multishop:hover span.icon-trendDraw {
    background-image: url('/src/img/IconGreyTrendNo.png');
  }
  .btn-kpi-multishop:hover span.icon-trendDown {
    background-image: url('/src/img/IconRedTrendDown.png');
  }
} */