#DottedMenuTargetCard .dropdown-toggle::after{
    display: none;
}

#DottedMenuTargetCard .dropdown-menu{
border: none;
border-radius:10px;
box-shadow: 0 2px 10px rgb(22 34 44 / 17%);
}

#DottedMenuTargetCard .dropdown-item div{
    border-bottom: 2px solid var(--medium-grey);
    }
#DottedMenuTargetCard .dropdown-item.active, #DottedMenuTargetCard .dropdown-item:active {
        background-color: var(--light-grey);
}
.modalTargetCard  .modal-footer div{
    padding: 0;
    margin: 0;
}
.modalTargetCard  .modal-footer {
    padding: 0;
    margin: 0;
}
.modalTargetCard  .modal-footer  .border-right{
    border-right: 1px solid #dee2e6;
}

.modalTargetCard  .modal-header{
    border-bottom: none;
}
.modalTargetCard .modal-content{
    border-radius:20px;
    border:none;
}