#TabLineChartSingleShop #TabLineChartSingleShop_svg text{
     font-family: 'Lato' !important;

}

.legendComparison{
    border-radius: 100%;
    height: 15px;
    width: 15px;
    min-width: 15px;
}


.linechartTooltipTemplateSingleShop .icon-trendUp,.linechartTooltipTemplateSingleShop .icon-trendDown,.linechartTooltipTemplateSingleShop .icon-trendDraw{
    width: 6px;
    height: auto;
    display: block;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}
.linechartTooltipTemplateSingleShop{
  min-width: 80px;
  border: 2px solid var(--light-grey);
}
.linechartTooltipTemplateSingleShop  span.icon-trendUp{
    background-image: url('/src/img/IconWhiteTrendUp.png');
    opacity: .8;

  }
  
  .linechartTooltipTemplateSingleShop span.icon-trendDraw{
    background-image: url('/src/img/IconGreyTrendNo.png');
    opacity: .8;
  }
  
  .linechartTooltipTemplateSingleShop span.icon-trendDown {
    background-image: url('/src/img/IconWhiteTrendDown.png');
    opacity: .8;
  }

  /* #TabLineChartSingleShop_tooltip div#TabLineChartSingleShop_tooltipparent_template {
    display: block !important;
    opacity: 1 !important;
}
#TabLineChartSingleShop_tooltip div#TabLineChartSingleShop_tooltipparent_template div {
  display: block !important;
  opacity: 1 !important;
  width: 60px;
  height: 60px;
  background-color: aqua;
} */