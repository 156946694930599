#languageSelectorFooter{
    height: 40px;
    width: 30px;
}
#languageSelectorFooter .lang-list {
    position: absolute;
    bottom: 0;
    width: 50px;
}
#languageSelectorFooter .lang-list-menu {
    border-radius: 7px;
    margin-left: -10px;
}
 
#languageSelectorFooter .lang-list .lang-item {
    padding: 0;
    border: none;
    background: none;
}
#languageSelectorFooter .lang-list .lang-item img{
    border-bottom: 3px solid var(--light-grey);

}
#languageSelectorFooter .lang-list .lang-item:last-child img{
    border-bottom: none;
}

#languageSelectorFooter .lang-list .lang-item:not(.active) {
    display: none;
}
 
#languageSelectorFooter .lang-list:hover .lang-item {
    display: inherit;

}

 
#languageSelectorFooter .lang-list-menu .lang-item:hover {
    background:var(--light-grey);
    border-radius: 0;
}

#languageSelectorFooter .lang-list-menu .lang-item:first-child:hover {
    background:var(--light-grey);
    border-radius: 7px 7px 0 0;
}
#languageSelectorFooter .lang-list-menu .lang-item:last-child:hover {
    background:var(--light-grey);
    border-radius: 0 0 7px 7px;
}

#languageSelectorFooter .lang-list-menu img {
    padding-bottom: 5px;
    width: 30px;
    margin: auto ;
}