#languageSelector {
    width: fit-content;
}
#languageSelector button {
    border: none;
    background-color: transparent;
     padding-right: 0;
    padding-left: 0; 
    padding-bottom: 0;
    padding-top: 0;
}

#languageSelector .buttonactive{
    height: 30px;
}
#languageSelector .dropdown-menu{
    margin-top: 6px;
    min-width: 10px;
    width: max-content;
    right: 0 !important;
    left: -12px !important;
    border-radius: 10px;
    border: none;
    box-shadow: 0 5px 10px #00000033;
}
#languageSelector  .btn-check:focus+.btn, .btn:focus {
    outline: 0;
    box-shadow: none;
}

#languageSelector  .dropdown-toggle::after{
    display:none
}
#languageSelector hr{
    background-color: var(--light-grey);
    height: 3px;
    border-radius: 20px;
    opacity: 1;
    margin: 0 5px;
}
#languageSelector a:last-child  hr{
    display:none ;
}
#languageSelector .dropdown-item {
    padding: 0 ;
}
#languageSelector .dropdown-item:last-child {
    border-bottom: none;
}

#languageSelector .dropdown-item.active,
 #languageSelector .dropdown-item:active{
    background: linear-gradient(to right, #18E677, #2BE090, #3EDAA9, #51D4C2, #63CEDB, #76C8F4, #85B8FE, #91A0FE, #9D88FE, #A970FD, #B458FD);
}

#languageSelector  .flagDropped{
    padding: 0 12px;
}
