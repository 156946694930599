/* SYNCFUSION */
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-navigations/styles/material.css';
@import "../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";


@import "../node_modules/@syncfusion/ej2-react-grids/styles/material.css";
/* REACT BOOTSTRAP  https://react-bootstrap.netlify.app/getting-started/introduction/ */
@import 'bootstrap/dist/css/bootstrap.min.css';

/*GOOGLE FONTS*/
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');


/*BLOCK ROTATION*/


/* @media screen  and (max-device-width: 767px) and (orientation: landscape) {
  html #root {
  
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    height: 100vw;
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    left: 0;
  }
} */


:root {
  --black: #16222C;
  --dark-grey: #737A80;
  --medium-grey: #C0C3D1;
  --medium-grey-op50: #c0c3d17e;
  --light-grey: #EFEFEF;
  --white: #ffffff;
  --azure:#788BFF; 
  --tiffany: #5DC6D0;
   --purple:#B95DFD; 
  /* --green:#2ECD91; */
  --violet:#A081FD;
  --dark-violet:#A05CFD;
   --celeste:#7BC1F7;
  --light-green:#47D7B6;
  --redError:#EC373D;
  --greenSuccess:#35cf94;
  --yellowWarning: #F7B842;  
  --body-grey: #F5F6FB;
  --orange:#F2B511;
  --red:#F45971;
  --green:#43DAA8;

}
#Embed {
  font-family: 'Lato';
}
* body{
  font-family: 'Lato'  !important;
  min-height: 100vh;
/* background: linear-gradient(215deg, #18E677, #2BE090,#3EDAA9,#51D4C2,#63CEDB,#76C8F4,#85B8FE,#91A0FE,#9D88FE,#A970FD,#B458FD); */
background-color:var( --body-grey) ;
}
* .e-control, .e-css{
  font-family: 'Lato'  !important;
}
.bg-white{
  background-color: var(--white);
}
.bg-tiffany{
  background-color: var(--tiffany);
}
.bg-purple{
  background-color: var(--purple);
}
.bg-orange{
  background-color: var(--orange);
}
.bg-red{
  background-color: var(--red);
}

.bg-green{
  background-color: var(--green);
}
.bg-medium-grey{
  background-color: var(--medium-grey);
}

.bgGreyTrasparent{ 
  background-color: #afbbbb61;
}
.bg-grad{
  background: -webkit-linear-gradient(180deg, #B65AFD 0%, #70B3ED 50%,#4CDEB1 100%);
}
.bg-grad-op25{
  background: -webkit-linear-gradient(180deg, rgb(182 90 253 / 25%) 0%, rgb(112 179 237 / 25%) 50%,rgb(76 222 177 / 25%) 100%);
}
.bg-grad-tiffany{
  background: -webkit-linear-gradient(#98E2D9 , #5DC6D0 );
}
.bg-grad-purple{
  background: -webkit-linear-gradient(#C093F4 ,#A05CFD );
}
.bg-grad-tiffany-op25{
  background: -webkit-linear-gradient(#98e2d94d , #5dc6d04d );
}
.bg-grad-purple-op25{
  background: -webkit-linear-gradient(#c093f44d ,#a05cfd4d );
}
.bg-white-md {
  background-color: var(--white);
}
.bg-white-sm {
  background-color: var(--white);
}
@media screen and (max-width: 768px) {
.bg-white-md {
  background-color: initial !important;
}
}
@media screen and (max-width: 576px) {
  .bg-white-sm {
    background-color: initial !important;
  }
  }
.c-black { color: var(--black); }
.c-dark-grey { color: var(--dark-grey); }
.c-medium-grey { color: var(--medium-grey); }
.c-red { color:var(--red); }
.c-green { color:var(--green); }
.c-purple { color:var(--purple); }
.c-azure { color:var(--azure); }
.c-tiffany { color:var(--tiffany); }
.c-violet { color:var(--violet); }
.c-dark-violet { color:var(--dark-violet); }
.c-heavenly { color:var(--celeste); }
.c-light-green { color:var(--light-green); }
.c-white { color: var(--white); }
.c-gradient {
  background: -webkit-linear-gradient(180deg, #18E677 0%, #2BE090 10%,#3EDAA9 20%,#51D4C2 30%,#63CEDB 40%,#76C8F4 50%,#85B8FE 60%,#91A0FE 70%,#9D88FE 80%,#A970FD 90%,#B458FD 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: fit-content;
}
.c-white-op80{
  color: #ffffffcc;
}
.font-weight-bold { font-weight: 800; }
.font-weight-semibold { font-weight: 600; }
.font-weight-regular { font-weight: 400; }
@media screen and (max-width: 768px) {
.font-weight-sm-bold { font-weight: 800; }
.font-weight-sm-semibold { font-weight: 600; }
.font-weight-sm-regular { font-weight: 400; }
}
.font-weight-sm-bold,.font-weight-semibold, .font-weight-regular {
  line-height: 1.3;
}
.font-size-46 { font-size: 46px; }
.font-size-38 { font-size: 38px; }
.font-size-36 { font-size: 36px; }
.font-size-32 { font-size: 32px; }
.font-size-28 { font-size: 28px; }
.font-size-25 { font-size: 25px; }
.font-size-24 { font-size: 24px; }
.font-size-22 { font-size: 22px; }
.font-size-21 { font-size: 21px; }
.font-size-20 { font-size: 20px; }
.font-size-19 { font-size: 19px; }
.font-size-18 { font-size: 18px; }
.font-size-17 { font-size: 17px; }
.font-size-16 { font-size: 16px; }
.font-size-15 { font-size: 15px; }
.font-size-14 { font-size: 14px; }
.font-size-13 { font-size: 13px; }
.font-size-12 { font-size: 12px; }
.font-size-11 { font-size: 11px; }
@media screen and (max-width: 768px) {
  .font-size-sm-46 { font-size: 46px; }
  .font-size-sm-38 { font-size: 38px; }
  .font-size-sm-36 { font-size: 36px; }
  .font-size-sm-32 { font-size: 32px; }
  .font-size-sm-28 { font-size: 28px; }
  .font-size-sm-25 { font-size: 25px; }
  .font-size-sm-24 { font-size: 24px; }
  .font-size-sm-22 { font-size: 21px; }
  .font-size-sm-21 { font-size: 21px; }
  .font-size-sm-20 { font-size: 20px; }
  .font-size-sm-19 { font-size: 19px; }
  .font-size-sm-18 { font-size: 18px; }
  .font-size-sm-17 { font-size: 17px; }
  .font-size-sm-16 { font-size: 16px; }
  .font-size-sm-15 { font-size: 15px; }
  .font-size-sm-14 { font-size: 14px; }
  .font-size-sm-13 { font-size: 13px; }
  .font-size-sm-12 { font-size: 12px; }
  .font-size-sm-11 { font-size: 11px; }
  }


.img-icon100{
  width: 100px;
  height: 100px;
  object-fit: contain;
  }
  .img-icon90{
    width: 90px;
    height: 90px;
    object-fit: contain;
    }
    .img-logo80{
      width: 80px;
      object-fit: contain;
    }
.img-icon80{
  width: 80px;
  height: 80px;
  object-fit: contain;
  }
.img-icon60{
  width: 60px;
  height: 60px;
  object-fit: contain;
}
.img-icon50{
  height: 50px;
  object-fit: contain;
}
.img-icon40{
  width: 40px;
  height: 40px;
  object-fit: contain;
}
.img-icon35{
  height: 35px;
  width: 35px;
  object-fit: contain;
  }
.img-icon30{
  height: 30px;
  width: 30px;
  object-fit: contain;
  }
.img-icon25{
  height: 25px;
  width: 25px;
  object-fit: contain;
  }
.img-icon20{
  height: 20px;
  width: 20px;
  object-fit: contain;
}
.img-icon15{
  height: 15px;
  width: 15px;
  object-fit: contain;
}
.img-icon12{
  height: 12px;
  width: 12px;
  object-fit: contain;
}
.img-icon10{
  height: 10px;
  width: 10px;
  object-fit: contain;
}

@media screen and (max-width: 768px) {
  .img-sm-icon40{
    width: 40px;
    height: 40px;
    object-fit: contain;
  } 
  .img-sm-icon50{
    width: 50px;
    height: 50px;
    object-fit: contain;
  } 

}
.shadowDropLessBlur{
  box-shadow: 0 2px 10px #16222c0f;
}
.shadowDropMediumBlur{
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
}
.shadowDrop-md {
  box-shadow: 0 3px 16px #16222c0f;
}


@media screen and (max-width: 768px) {
  .shadowDrop-md {
    box-shadow:none;
  }
  }

 
  
.border-radius{
  border-radius: 15px;
}
.e-grid .e-hide {
  display: none;
}
.position-absolute{
  position: absolute;
} 
.pointer{
  cursor: pointer;
}
.border-none{
  border: none;
}
.text-wrao{
  overflow-wrap: anywhere;
}

.text-decoration-underline {
  text-decoration: underline;
}
.text-decoration-none {
  text-decoration: none;
}
.not-selectable {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
.mt-n5 {
  margin-top: -2.5rem ;
}

@media screen and (max-width: 768px) {
  .mt-n5 {
    margin-top: 0;
  }
}
.flex-1{
  flex: 1;
}

div.overflowChart{
  overflow: visible !important;
}
div.overflowChart svg{
  overflow: visible !important;
}
.min-height-520{
  min-height: 520px;
}
/* //FORM AND INPUT// */
.autenticationBody{
  background-image: url("/src/img/LoginBG.png");
  background-position: center;
  background-size: cover;
  min-height: 100vh;
}


/* BUTTON */

/* gradientButton */
 .gradientButton{
  text-transform: none;
   color:var(--white);
  background: linear-gradient(115deg, #18E677 0%, #2BE090, #3EDAA9, #51D4C2, #63CEDB, #76C8F4, #85B8FE, #91A0FE, #9D88FE, #A970FD, #B458FD 100%);
  border-radius: 30px;
  background-repeat: no-repeat;
  border:none;
  -webkit-transition: opacity .3s ease-out;  
  -moz-transition:  opacity .3s ease-out;  
  -o-transition:  opacity .3s ease-out;  
  transition:  opacity .3s ease-out;  
  width: fit-content;
  align-self: center;
  padding: 8px 10px;
  min-width: 130px;
  font-size: 16px;
  font-weight: 600;
  box-shadow:none;
 }
 .gradientButton:hover{
  color:var(--white);
   opacity:.8;
   box-shadow:none;
   }

   .gradientButton:focus{
    color:var(--white);
    opacity:1;
    box-shadow:none;
     }
 .gradientButton:disabled{
  color:var(--white);
  opacity:.5;
  box-shadow:none;
 }

/* radiusButton */
 .tiffanyButton{
  border-radius: 100px;
  text-transform: none;
   color:var(--white);
  background: linear-gradient(180deg, #98E2D9 0%,  #5DC6D0 100%);
  border-radius: 30px;
  background-repeat: no-repeat;
  border:none;
  -webkit-transition: opacity .3s ease-out;  
  -moz-transition:  opacity .3s ease-out;  
  -o-transition:  opacity .3s ease-out;  
  transition:  opacity .3s ease-out;  
  width: fit-content;
  align-self: center;
  padding: 8px 10px;
  min-width: 130px;
  font-size: 17px;
  font-weight: 600;
 }
 .tiffanyButton:hover{
  color:var(--white);
   opacity:.9;
   }

   .tiffanyButton:focus{
    color:var(--white);
    opacity:1;
     }
 .tiffanyButton:disabled{
  color:var(--white);
  opacity:.5;
 }


/*BUTTON RED LINE */
.redlineButton{
  background-color: transparent;
  border-radius: 30px;
  border: 1px solid var(--red);
  color: var(--red);
  box-shadow: none;
  text-transform: inherit;
  height: fit-content;
  
}
.redlineButton:hover,
.e-btn.redlineButton:active,
.e-css.redlineButton.e-btn:active,
.redlineButton.e-btn:focus, .e-css.redlineButton.e-btn:focus{
  background-color: var(--red);
  color: var(--white);
  box-shadow: none;
}
.redlineButton.e-btn:disabled,
.redlineButton.e-css.e-btn:disabled{
  background-color: transparent;
  border: 1px solid var(--red);
  color: var(--red);
  box-shadow: none;
  opacity: .5;;
}
/* SCROLLBAR */
.scrollbarCustom ::-webkit-scrollbar {
  width: 7px;
  height: 7px;

}

/* Track */
.scrollbarCustom ::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 10px;
}
 
/* Handle */
.scrollbarCustom ::-webkit-scrollbar-thumb {
  background: rgb(199, 199, 199); 
  border-radius: 10px;
}
.list-style-none{
  list-style: none;
}



/*Progress Bar */
 .progress{
  border-radius: 20px;
  background-color: #e1e2e6ab;
  height: 11px;
}

.progress .progress-bar{
border-radius: 20px;
}

.transition{
  -webkit-transition: all .3s ease-out;  
  -moz-transition:  all .3s ease-out;  
  -o-transition:  all .3s ease-out;  
  transition:  all .3s ease-out;  
}

/* circle Name */
.circleName-xs{
  text-transform: uppercase;
  border-radius: 100%;
  font-size: 14px;
  height: 22px;
  width: 22px;
  min-width: 20px;
  overflow: hidden;
  color: var(--white);
  display: flex;
  background:  var(--medium-grey);
  align-items: center;
  justify-content: center;
}

.circleName-m{
  text-transform: uppercase;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  min-width: 30px;
  overflow: hidden;
  color: var(--white);
  display: flex;
  background: linear-gradient(180deg, #B65AFD 0%, #70B3ED 50%, #4CDEB1 100%);
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}




/* CHANGE
LAYOUT SINGLESHOP */

.w-50-100{
  width: 50%;
  flex-shrink: 0;
}
@media screen and (max-width: 768px) {
  .w-50-100{
    width: 100%;
    flex-shrink: 0;
 
  }
  }