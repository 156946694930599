#DatePickerNavbar #DatePicker .stv-radio-buttons-wrapper .buttonRadio:first-child {
  border-bottom-left-radius: 40px;
  border-top-left-radius: 40px;
}

#DatePickerNavbar #DatePicker .stv-radio-buttons-wrapper .buttonRadio:last-child {
  border-bottom-right-radius: 40px;
  border-top-right-radius: 40px;
}

#DatePickerNavbar #dateInputGroup .stv-radio-button+label {
  padding: .25em 1em;
  font-size: 14px;
  color: var(--white);
}

#DatePickerNavbar #dateInputGroup .stv-radio-button:checked+label {
  background: -webkit-linear-gradient(90deg, #E1F7F5 0%, #FFFFFF 100%);
  color: var(--black);
  font-weight: bolder;
}

#DatePickerNavbar .e-datepicker {
  height: 30px;
  color: var(--white);
  font-size: 14px;
}

#DatePickerNavbar .buttonRadio:first-child .stv-radio-button:checked+label {
  border-bottom-left-radius: 39px;
  border-top-left-radius: 39px;
}

#DatePickerNavbar .buttonRadio:last-child .stv-radio-button:checked+label {
  border-bottom-right-radius: 39px;
  border-top-right-radius: 39px;
}

/* PICK DATE */

#DatePickerNavbar #DatePicker span.e-input-group-icon.e-date-icon.e-icons {
  height: 268px;
  margin: 0;
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-radius: 0 40px 40px 0;
  border: 1px solid var(--white);
  border-left: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

#DatePickerNavbar #DatePicker .datepicker-control-section span.e-input-group.e-control-wrapper.e-date-wrapper.e-valid-input {
  height: 32px;
}

#DatePickerNavbar #DatePicker input#calendar {
  background-color: transparent;
  color: var(--white);
  text-transform: capitalize;
  padding: 8px;
  border: 1px solid var(--white);
  border-right: none;
  border-radius: 40px 0 0 40px;
}

/*hide default Icon and show the custom */

#DatePickerNavbar #DatePicker .e-input-group-icon.e-date-icon::before, #DatePickerNavbar #DatePicker .e-control-wrapper .e-input-group-icon.e-date-icon::before {
  width: 26px;
  content: "";
  background-image: url(/src/img/IconLightCalendar.png);
  height: 26px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

#DatePickerNavbar #datePicker span.e-input-group-icon.e-date-icon.e-icons {
  height: 30px;
}

#DatePickerNavbar #datePicker .e-input-group-icon.e-date-icon::before, #DatePickerNavbar #DatePicker .e-control-wrapper .e-input-group-icon.e-date-icon::before {
  width: 24px;
  content: "";
  background-image: url(/src/img/IconLightCalendar.png);
  height: 24px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}