.targetCardList .targetCard{
    background-image: url('/src/img/BgCardTarget.png');
    background-repeat: no-repeat;
    background-position: center right;
    min-height: 400px;
}
.targetCardList .targetCard .circleName{
    border-radius: 100%;
    background-color: var(--medium-grey);
    height: 28px;
    width: 28px;
    overflow: hidden;
}
.targetCardList .targetCard .circleName-s{
    border-radius: 100%;
    background-color: var(--medium-grey);
    height: 24px;
    width: 24px;
    overflow: hidden;
}
.targetCardList .targetCard .circleName-grad-s{
    border-radius: 100%;
  background: linear-gradient(45deg, #18E677, #2BE090, #3EDAA9, #51D4C2, #63CEDB, #76C8F4, #85B8FE, #91A0FE, #9D88FE, #A970FD, #B458FD);
    height: 24px;
    width: 24px;
    overflow: hidden;
}

.targetCardList .targetCard .progressGrad .progress-bar{
    background: linear-gradient(45deg, #18E677, #2BE090, #3EDAA9, #51D4C2, #63CEDB, #76C8F4, #85B8FE, #91A0FE, #9D88FE, #A970FD, #B458FD);
}

.targetCardList .targetCard .progressGrey .progress-bar{
   background-color: var(--medium-grey);
}

.targetCardList .newTarget{
    border-radius: 15px;
    padding: 5px;
    background: linear-gradient(180deg, #b65afdb3, #70B3EDb3, #4CDEB1b3);
 
}


.targetCardList .newTarget{
    background: -webkit-linear-gradient(180deg, #B65AFD 0%, #70B3ED 50%,#4CDEB1 100%);
}