#NavbarLogo .logoSaga {
    border-radius: 100%;
    background: var(--white);
}

#navbarMain .navbar-collapse.collapse {
    justify-content: flex-end;
}

#navbarMain {
    position: sticky;
    z-index: 1040;
    width: 100%;
    top: 0;
}