.e-chevron-right-03:before {
    content: '\e653';
}

#breadcrumb .hoverbreadcrumb:hover {
    background: -webkit-linear-gradient(180deg, #18E677 0%, #2BE090 10%, #3EDAA9 20%, #51D4C2 30%, #63CEDB 40%, #76C8F4 50%, #85B8FE 60%, #91A0FE 70%, #9D88FE 80%, #A970FD 90%, #B458FD 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: fit-content;
    color: #B65AFD;
    -webkit-transition: all .7s ease-out;
    -moz-transition: all .7s ease-out;
    -o-transition: all .7s ease-out;
    transition: all .7s ease-out;
}

#breadcrumb p:last-child .hoverbreadcrumb {
    font-weight: 700;
}