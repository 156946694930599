#TargetCard .circleName {
    border-radius: 100%;
    background-color: var(--medium-grey);
    height: 32px;
    width: 32px;
    overflow: hidden;
}

#TargetCard .progress-description {
    min-width: 50%;
}

#TargetCard.storeStyle  .progress-bar {
    background: linear-gradient(270deg, #5DC6D0 0%, #98E2D9 100%);
}

#TargetCard.onlineStyle  .progress-bar {
    background: linear-gradient(270deg, #A05CFD 0%, #C093F4 100%);
}

#TargetCard.storeStyle .progress-description .progress-value {
    color: var(--tiffany)
}

#TargetCard.onlineStyle .progress-description .progress-value {
    color: var(--violet)
}

/* Button */

#TargetCard .addTarget-button {
    text-transform: none;
    border-radius: 50px;
    color: var(--white);
    background-repeat: no-repeat;
    border: none;
    -webkit-transition: opacity .3s ease-out;
    -moz-transition: opacity .3s ease-out;
    -o-transition: opacity .3s ease-out;
    transition: opacity .3s ease-out;
    width: fit-content;
    align-self: center;
    padding: 8px 30px;
    min-width: 130px;
    font-size: 17px;
    font-weight: 600;
    box-shadow: none;
}

@media (max-width: 768px) {
    #TargetCard button.addTarget-button {
        width: 100%;
    }
  }


#TargetCard .addTarget-button:focus {
    color: var(--white);
    opacity: 1;
}

/* tiffanyButton */

#TargetCard.storeStyle .addTarget-button {
    background: linear-gradient(0deg, #5DC6D0 0%, #98E2D9 100%);
}

#TargetCard.storeStyle .addTarget-button:hover {
    color: var(--white);
    background: #5DC6D0;
}

/* Violet Button */

#TargetCard.onlineStyle .addTarget-button {
    background: linear-gradient(0deg, #A05CFD 0%, #C093F4 100%);
}

#TargetCard.onlineStyle .addTarget-button:hover {
    color: var(--white);
    background: #A05CFD;
}

#TargetCard .circleName-s {
    border-radius: 100%;
    background-color: var(--medium-grey);
    height: 24px;
    width: 24px;
    overflow: hidden;
}

#TargetCard .circleName-grad-s {
    border-radius: 100%;
    background: linear-gradient(45deg, #18E677, #2BE090, #3EDAA9, #51D4C2, #63CEDB, #76C8F4, #85B8FE, #91A0FE, #9D88FE, #A970FD, #B458FD);
    height: 24px;
    width: 24px;
    overflow: hidden;
}