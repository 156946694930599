@media screen and (max-width: 769px) {
    #table-physical.it td:nth-of-type(1):before {
      content: "Cassa";
    }
    #table-physical.it td:nth-of-type(2):before {
      content: "Fatturato";
    }
    #table-physical.it td:nth-of-type(3):before {
      content: "Scontrino";
    }
    #table-physical.it td:nth-of-type(4):before {
      content: "Scontrino medio";
    }
  
    #table-physical.fr td:nth-of-type(1):before {
        content: "Caisse";
    }
    #table-physical.fr td:nth-of-type(2):before {
        content: "Chiffre d'affaires";
    }
    #table-physical.fr td:nth-of-type(3):before {
        content: "Tickets";
    }
    #table-physical.fr td:nth-of-type(4):before {
        content: "Ticket moyen";
    }
}