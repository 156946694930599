#billing .e-grid td.e-active{
    background-color: transparent;
}
/* CHECK BOX CUSTOM IN TABLE GRID */
#billing .e-checkbox-wrapper .e-frame.e-check,
#billing .e-css.e-checkbox-wrapper .e-frame.e-check,
#billing .e-checkbox-wrapper .e-frame,
#billing .e-css.e-checkbox-wrapper .e-frame{
    background-color: transparent;
    border: 1px solid #CED4DA;
    border-radius: 5px;
    color:var(--black);
    font-size: 16px;
}

/*Responsive Grid */
#billing  .e-bigger .e-grid.e-row-responsive .e-gridcontent td::before,
#billing .e-bigger .e-grid.e-row-responsive .e-summarycontent td::before,
#billing .e-bigger.e-grid.e-row-responsive .e-gridcontent td::before,
#billing .e-bigger.e-grid.e-row-responsive .e-summarycontent td::before{
font-weight: bolder;
}

#billing .e-bigger .e-grid.e-row-responsive .e-gridcontent td.e-gridchkbox[data-cell]{
padding-left:12px;
}

#billing .e-res-toolbar.e-toolbar{
    display: none;
}
#billing  .e-bigger .e-grid.e-row-responsive .e-table, #billing  .e-bigger.e-grid.e-row-responsive .e-table{
border-top: 1px solid #e0e0e0;
}



#billing .e-bigger .e-grid.e-row-responsive > .e-gridheader, #billing  .e-bigger.e-grid.e-row-responsive > .e-gridheader{
   
    display: block;
}

#billing .e-bigger .e-grid.e-row-responsive .e-table, #billing .e-bigger.e-grid.e-row-responsive .e-table{
    border-top: none;
}
#billing .e-bigger .e-grid.e-row-responsive > .e-gridheader th:nth-child(1), #billing  .e-bigger.e-grid.e-row-responsive > .e-gridheader th:nth-child(1){
   
    display: flex;
    padding-left: 12px;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}
#billing .e-bigger .e-grid.e-row-responsive > .e-gridheader th, #billing  .e-bigger.e-grid.e-row-responsive > .e-gridheader th{
    display: none;
}

/*End Responsive */
/*Paginator */
#billing  .e-bigger .e-pager div.e-parentmsgbar, #billing .e-pager.e-bigger div.e-parentmsgbar{
    display: none;
}

#billing  .e-gridpager.e-control.e-pager.e-lib{
    place-content: center;
    display: flex;
}



#billing   .e-pager .e-currentitem, #billing .e-pager .e-currentitem:hover{
 color:var(--black);
 background-color:transparent;
 font-weight: bolder;
}


#billing .e-pager .e-pagercontainer {
    display: block;
}




#billing  .e-pager .e-mfirst, #billing .e-pager .e-mprev, #billing .e-pager .e-mnext, #billing .e-pager .e-mlast{
    display: none;
}


#billing .e-pager div.e-parentmsgbar{
    display: none;
}






  
