.responsive-tablePhysical .table>:not(:first-child){
  border-top: none;
}


.responsive-tablePhysical table th:first-child {
  border-radius: 12px 0 0 12px;
}

.responsive-tablePhysical table th:last-child {
  border-radius: 0 12px 12px 0;
}

.responsive-tablePhysical table th {
  height: 65px;
  text-align: center;
  color: var(--white);
  vertical-align: middle;
}

.responsive-tablePhysical table {
  border-collapse: separate;
  border-spacing: 0 1rem;
}

.responsive-tablePhysical .table>:not(caption)>*>* {
  padding: 3px;
  box-shadow: none;
}

.responsive-tablePhysical .tbody, td, tfoot, th, thead, tr {
  border-bottom: none;
}

.responsive-tablePhysical tr {
  border-collapse: separate;
  border-spacing: 0 3rem;
  border-radius: 12px
}

.responsive-tablePhysical tr td:first-child {
  border-radius: 12px 0 0 12px;
}

.responsive-tablePhysical tr td:last-child {
  border-radius: 0 12px 12px 0;
}

.responsive-tablePhysical .responsive-sort {
  display: none;
}

@media screen and (max-width: 769px) {
  .responsive-tablePhysical .responsive-sort {
    display: unset;
  }
  .responsive-tablePhysical tr td:first-child {
    border-radius: 15px 15px 0 0;
  }
  .responsive-tablePhysical tr td:last-child {
    border-radius: 0 0 15px 15px;
  }
  .responsive-tablePhysical td a, .responsive-tablePhysical td p {
    white-space: nowrap;
    flex: 1;
    text-align: right !important;
  }
  /* Force table to not be like tables anymore */
  .responsive-tablePhysical table, .responsive-tablePhysical thead, .responsive-tablePhysical tbody, .responsive-tablePhysical th, .responsive-tablePhysical td, .responsive-tablePhysical tr {
    display: block;
  }
  /* Hide table  */
  .responsive-tablePhysical thead tr {
    display: none;
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  .responsive-tablePhysical tr {
    margin: 0 0 1rem 0;
  }
  .responsive-tablePhysical td {
    border: none;
    flex-wrap: wrap;
    display: flex;
  }
  .responsive-tablePhysical td:before {
    color: var(--medium-grey);
    font-size: 16px;
    font-weight: 800;
    padding-left: 30px;
    white-space: nowrap;
    margin-right: 10px;
    flex: 1;
  }
  .responsive-tablePhysical table tbody tr:before {
    width: 18px;
    content: "";
    height: calc(100% - 60px);
    display: flex;
    flex-direction: row;
    background: -webkit-linear-gradient(270deg, #96E1D9 0%,  #5EC6D0 100%);
    margin: 10px 10px 10px 0;
    border-radius: 20px;
    float: left;
    position: absolute;
  }
  .responsive-tablePhysical .responsiveTableContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .responsive-tablePhysical tr {
    background-color: var(--white);
    padding: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
  }
}