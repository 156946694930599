.rowInnerCard{
    display: flex;
    flex-wrap: wrap;
}

.totalTurnover{
    background-image: url("/src/img/BgOpacityPhoto.png");
}
.totalTransactions{
    background-image: url("/src/img/BgOpacityBill.png");
  
}


.cardPieOverview{
background-size: contain;
    background-repeat: no-repeat;
    background-position: 100% 45px;
}