/* GRADIENT INPUT */

.gradient-input-round {
  padding: 1px;
  border-radius: 80px;
  background: linear-gradient(to left, #B65AFD 0%, #70B3ED 50%, #4CDEB1 100%);
  cursor: pointer;
}

/* //FORM AND INPUT// */

.gradient-input {
  height: 32px;
  display: flex;
  position: relative;
  padding: 1px;
  box-sizing: border-box;
  width: fit-content;
  background: linear-gradient(to right, #18E677, #2BE090, #3EDAA9, #51D4C2, #63CEDB, #76C8F4, #85B8FE, #91A0FE, #9D88FE, #A970FD, #B458FD);
  border-radius: 31px;
}

/* INPUT */

.gradient-input .e-input {
  border-radius: 30px;
  border: none;
}

.gradient-input.input-show-hide .e-input {
  border-radius: 30px 0 0 30px;
}

.gradient-input .e-input:focus-visible {
  color: var(--dark-grey);
  background-color: #fff;
  -webkit-appearance: none;
  outline: none;
}

/* Input end */

/* Select */

.gradient-input .e-select {
  border-radius: 30px;
  border: none;
}

.gradient-input.input-show-hide .e-select {
  border-radius: 630px 0 0 30px;
}

.gradient-input.input-show-hide span {
  border-radius: 0 30px 30px 0;
}

.gradient-input .e-select:focus-visible {
  color: var(--dark-grey);
  outline: none;
}

/* Select End*/

/* DROPDOWN CHECKBOX INPUT */

.show.dropdown .gradient-input-round {
  background: linear-gradient(to left, #B65AFD 0%, #70B3ED 50%, #4CDEB1 100%);
  background-color: #71b3ed;
  color: var(--white);
}

.dropdown .gradient-input-round .input-grad-innercontainer {
  border-radius: 80px;
  background: #F5F6FB;
  color: var(--black);
  -webkit-transition: all .15s ease-out;
  -moz-transition: all .15s ease-out;
  -o-transition: all .15s ease-out;
  transition: all .15s ease-out;
}

.show.dropdown .gradient-input-round .input-grad-innercontainer {
  border-radius: 80px;
  color: var(--white);
  background: linear-gradient(to left, #B65AFD 0%, #70B3ED 50%, #4CDEB1 100%);
  -webkit-transition: all .15s ease-out;
  -moz-transition: all .15s ease-out;
  -o-transition: all .15s ease-out;
  transition: all .15s ease-out;
}

.dropdown .gradient-input-round h3::after {
  display: inline-block;
  align-self: center;
  content: "";
  color: var(--green);
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
  margin-top: 3px;
  margin-left: 25px;
}

.show.dropdown .gradient-input-round h3::after {
  color: var(--white);
}

.dropdown .dropdown-toggle::after {
  display: none;
}

.dropdown-menu.show {
  z-index: 1039;
  border-radius: 16px;
  border: none;
  box-shadow: 0px 2px 10px #16222c0f;
}
.formDropDownTree .dropdown-menu.show {
  z-index: 99;
}



.dropdown-item.active, .dropdown-item:active {
  background-color: var(--light-grey);
  color: var(--dark-grey);
}

.dropdown .gradient-input-round h3 {
  font-weight: 500;
}

.show.dropdown .gradient-input-round h3 {
  font-weight: bold;
}

.gradient-input-round .e-select {
  border-radius: 80px;
  border: none;
  min-height: 32px;
  min-width: 200px;
  padding: 0 8px;
}

.gradient-input-round .e-select {
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
  padding-right: 15px;
  background-image: url("/src/img/IconGradArrowDownSelect.png");
  background-position: calc(100% - 8px);
  background-repeat: no-repeat;
  background-size: 12px;
}

.gradient-input-round.disabled {
  opacity: .5;
}

.gradient-input .iconInput {
  object-fit: contain;
  width: 30px;
  height: auto;
  padding: 5px;
  border-radius: 0 100px 100px 0;
}


input.e-input.bg-white:disabled {
  background-color: #FFF !important;
  opacity: 1;
}


.gradient-input-round .input-show-hide .e-select {
  border-radius: 6px 0 0 6px;
}

.gradient-input-round .input-show-hide span {
  border-radius: 0 6px 6px 0;
}

.gradient-input-round .e-select:focus-visible {
  color: var(--dark-grey);
  outline: none;
}

/* 
   TREE VIEW FILTER */

#treeviewSidebarTemplateFilter .sotreIconTree {
  background-image: url("/src/img/IconGreyStore.png");
  background-repeat: no-repeat;
  height: 18px;
  width: 18px;
  background-repeat: none;
  background-size: contain;
}

/* CHECKBOX*/

/* CHECKBOX*/

/* GRADIENT CHECKBOX*/

.control {
  width: 100%;
  font-family: arial;
  display: block;
  position: relative;
  padding-left: 26px;
  margin-bottom: 5px;
  padding-top: 1px;
  cursor: pointer;
  font-size: 16px;
}

.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.control_indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #ffffff;
  border: 2px solid #C0C3D1;
  border-radius: 4px;
}

#treeviewSidebarTemplateFilter .control_indicator {
  top: 5px;
}

/* .control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
  background: #e869f3;
} */

.control input:checked~.control_indicator {
  background: -webkit-linear-gradient(180deg, #B65AFD 0%, #70B3ED 50%, #4CDEB1 100%);
}

.control:hover input:not([disabled]):checked~.control_indicator, .control input:checked:focus~.control_indicator {
  background: -webkit-linear-gradient(180deg, #B65AFD 0%, #70B3ED 50%, #4CDEB1 100%);
}

.control input:disabled~.control_indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}

.control_indicator:after {
  box-sizing: unset;
  content: '';
  position: absolute;
  display: none;
}

.control input:checked~.control_indicator:after {
  display: block;
}

.control-checkbox .control_indicator:after {
  left: 6px;
  top: 2px;
  width: 3px;
  height: 8px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.control-checkbox input:disabled~.control_indicator:after {
  border-color: #7b7b7b;
}

.control.control-checkbox:hover {
  background-color: var(--light-grey);
}

/* ANIMATION ON CLICK */

/* .control-checkbox .control_indicator::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 3rem;
  height: 3rem;
  margin-left: -1rem;
  margin-top: -0.8rem;
  background: -webkit-linear-gradient(180deg, #B65AFD 0%, #70B3ED 50%,#4CDEB1 100%);
  border-radius: 3rem;
  opacity: 0.3;
  z-index: 99999;
  transform: scale(0);
}
@keyframes s-ripple {
  0% {
      transform: scale(0);
  }
  20% {
      transform: scale(1);
  }
  100% {
      opacity: 0;
      transform: scale(1);
  }
}
@keyframes s-ripple-dup {
 0% {
     transform: scale(0);
  }
 30% {
      transform: scale(1);
  }
  60% {
      transform: scale(1);
  }
  100% {
      opacity: 0;
      transform: scale(1);
  }
}
.control-checkbox input + .control_indicator::before {
  animation: s-ripple 350ms ease-out;
}
.control-checkbox input:checked + .control_indicator::before {
  animation-name: s-ripple-dup;
} */

/* TREEVIEW TEMPLATE */

#treeviewSidebarTemplateFilter .circleName {
  border-radius: 100%;
  background-color: var(--medium-grey);
  height: 28px;
  width: 28px;
  min-width: 28px;
  overflow: hidden;
}

#treeviewSidebarTemplateFilter .storeIcon {
  background-image: url("/src/img/IconGreyStore.png");
  background-repeat: no-repeat;
  height: 20px;
  width: 20px;
  background-repeat: none;
  background-size: contain;
}

.e-treeview .e-list-item.e-active>.e-text-content .e-list-text #treeviewSidebarTemplateFilter .storeIcon, #treeviewSidebarTemplateFilter .active .storeIcon {
  background-image: url("/src/img/IconGradStore.png");
}

#treeviewSidebarTemplateFilter .sotreIconTree {
  background-image: url("/src/img/IconGreyStore.png");
  background-repeat: no-repeat;
  height: 28px;
  min-width: 22px;
  background-repeat: none;
  background-size: contain;
  background-position: center;
}

.e-treeview .e-list-item.e-active>.e-text-content .e-list-text #treeviewSidebarTemplateFilter .txtName, #treeviewSidebarTemplateFilter .active .txtName {
  font-weight: bolder;
}

#treeviewSidebarTemplateFilter .e-treeview .e-text-content {
  padding: 0;
}

#filterTargetModal .e-treeview .e-list-text, #filterTarget .e-treeview .e-list-text {
  padding: 0;
}

.e-treeview .e-list-item.e-active>.e-text-content .e-list-text #treeviewSidebarTemplateFilter .txtName, #treeviewSidebarTemplateFilter .active .txtName {
  font-weight: inherit !important;
}

.e-treeview:not(.e-fullrow-wrap) .e-list-item.e-active>.e-text-content {
  background: transparent;
}

#filterTargetModal .e-list-item.e-hover .e-text-content, #filterTarget .e-list-item.e-hover .e-text-content {
  background: transparent;
  border-color: transparent;
}

#filterTargetModal .e-treeview .e-ul, #filterTarget .e-treeview .e-ul {
  border: none;
  border-color: transparent;
}
#targetWizardContent .formDropDownTree button.btn {
  padding: 0;
  width: 100%;
}

.formDropDownTree.dropdown .gradient-input-round h3::after {
  content: '';
  border: none;
  width: 14px;
  height: 14px;
  background-image: url(/src/img/IconGradSelectArrow.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: inline-block;
  align-self: center;
}

.formDropDownTree.dropdown .gradient-input-round .input-grad-innercontainer {
  background: var(--white);
  color: var(--black);
  transition: all .15s ease-out;
  height: 30px;
}

.formDropDownTree.show.dropdown .gradient-input-round h3 {
  font-weight: 500;
}

input.e-numeric-hidden{
  display: none;
}

.gradient-input .e-control-wrapper.e-numeric{
  opacity: 1;
  width: 100%;

}

.gradient-input input.e-control.e-numerictextbox{
  height: 30px;
  opacity: 1;
  width: 100%;
  background: var(--white);
  color: var(--black);
  font-size: 16px;
  padding-left: 12px;
}
.input-radius-border{
  border-radius: 50px 0 0 50px;
}
span.e-control-wrapper.e-numeric.e-input-group.e-valid-input{
  display: flex;
}
span.e-input-group-icon.e-spin-down,span.e-input-group-icon.e-spin-up{
  width: 14px;
  margin: 3px;
  background-size: contain;
  height: auto;
  border-radius: 0;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}
span.e-input-group-icon.e-spin-down:hover,span.e-input-group-icon.e-spin-up:hover{
opacity: 0.5;
}
span.e-input-group-icon.e-spin-up{
  background-image: url("/src/img/IconInputNumberUp.png");
}
span.e-input-group-icon.e-spin-down{

  background-image: url("/src/img/IconInputNumberDown.png");
}