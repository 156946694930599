.tabHeaderButtonSingleShop{
    width: 100%;
    width: calc((100vw - 3rem )/3 - 40px );
}

.tabHeaderButtonSingleShop .txt-container {
     white-space: initial;
    }
 

.IconHeaderSingleShop{
    display: block;
    width: 60px;
    min-width: 60px;
    height: 60px;
    background-size: contain;
    object-fit: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.e-toolbar-item.e-active .tabHeaderButtonSingleShop .txt-container h2,
.e-toolbar-item.e-active .tabHeaderButtonSingleShop .txt-container p{
    color:#fff;
    transition: all .5s;
    opacity: 1;
}

.tabHeaderButtonSingleShop:hover .txt-container h2,
.tabHeaderButtonSingleShop:hover .txt-container p,
.tabHeaderButtonSingleShop.active .txt-container h2,
.tabHeaderButtonSingleShop.active .txt-container p{
    color:#fff;
    transition: all .5s;
    opacity: 1;
}

.tabHeaderButtonSingleShop.active.overviewHeaderButton{
    background: -webkit-linear-gradient(0deg, #4CDEB1 0%, #70B3ED 50%, #B65AFD 100%) !important;
}

.tabHeaderButtonSingleShop.active.storeHeaderButton{
    background: -webkit-linear-gradient(270deg, #98E2D9 0%, #5DC6D0 100%) !important;
}
.tabHeaderButtonSingleShop.active.onlineHeaderButton{
    background: -webkit-linear-gradient(270deg, #DA90FF 0%, #B558FD 100%) !important;
}

.tabHeaderButtonSingleShop.overviewHeaderButton:hover{
    background: -webkit-linear-gradient(0deg, #4CDEB1 0%, #70B3ED 50%, #B65AFD 100%) !important;
}

.tabHeaderButtonSingleShop.storeHeaderButton:hover{
    background: -webkit-linear-gradient(270deg, #98E2D9 0%, #5DC6D0 100%) !important;
}
.tabHeaderButtonSingleShop.onlineHeaderButton:hover{
    background: -webkit-linear-gradient(270deg, #DA90FF 0%, #B558FD 100%) !important;
}

.overviewHeaderButton .IconHeaderSingleShop{
    background-image: url("/src/img/IconOverview.png");
}

.overviewHeaderButton:hover .IconHeaderSingleShop,
.active.overviewHeaderButton .IconHeaderSingleShop{
    background-image: url("/src/img/IconOverviewWhite.png");
}

.storeHeaderButton .IconHeaderSingleShop{
    background-image: url("/src/img/IconTiffanyBigStore.png");
}
.storeHeaderButton:hover .IconHeaderSingleShop,
.active.storeHeaderButton .IconHeaderSingleShop{
    background-image: url("/src/img/IconWhiteBigStore.png");

}
.onlineHeaderButton .IconHeaderSingleShop{
    background-image: url("/src/img/IconEcommercePurple.png");
}
.onlineHeaderButton:hover .IconHeaderSingleShop,
.active.onlineHeaderButton .IconHeaderSingleShop{
    background-image: url("/src/img/IconEcommerceWhite.png");

}


/* ALTEZZA DEL TAB PIù ALTO */
/* #mainTabMultishop.e-tab .e-tab-header .e-toolbar-item .e-tab-text,
.e-text-wrap,
.e-tab-wrap,
 #mainTabMultishop.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap {
height: 100%;
display: flex;
}

.e-item.e-toolbar-item, .e-hscroll-content, .e-hscroll-content{
    display: flex;
    flex:1;
}
#mainTabMultishop.e-tab .e-tab-header .e-toolbar-item:not(.e-separator){
    flex: 1;
}

#mainTabMultishop.e-tab .e-tab-header .e-indicator {

    flex: 1;
    height: 100%;
}


.e-hscroll .e-hscroll-content{
    flex: 1;
    /* height: 100%; */
    /* display: flex;
}

#mainTabMultishop .e-items.e-toolbar-items{
    display: block;
}

*/


 /* dimension & Margin  */
 #mainTabSingleShop .tabHeaderButtonSingleShop {
    width: calc( (100vw - 3rem )/ 3);
    white-space: initial;
    min-height: 120px;
    margin: 0 0.5rem;
  }

  #mainTabSingleShop .headers {
    padding: 10px 0 ;
    width: auto !important;
    display: flex;
  }

  #mainTabSingleShop .tabHeaderButtonSingleShop:first-child   {
    margin-left: 0;
  }  
  #mainTabSingleShop .tabHeaderButtonSingleShop:last-child {
    margin-right: 0;
  }  

@media (max-width: 768px) {
    #mainTabSingleShop .tabHeaderButtonSingleShop {
      width:100%;
      min-width: 100%;
      margin: 0 1.5rem;
    }
  


  }
  /* dimension & Margin END */

@media (max-width: 768px) { 
    /* .tabHeaderButtonSingleShop{
        margin: auto;
      width: calc(100vw - 1.8rem );
        min-height:120px ;
    } */
   
    .IconHeaderSingleShop{
        width: 45px;
        min-width: 45px;
        height: 45px;
    }
}


@media (max-width: 992px) { 
    .IconHeaderSingleShop{
        width: 45px;
        min-width: 45px;
        height: 45px;
        margin-bottom: 10px;
    }
}
