#dateInputGroup  .stv-radio-button {
    position: absolute;
    left: -9999em;
}

#dateInputGroup .stv-radio-buttons-wrapper .buttonRadio {
      float: left;
      margin-right: -1px;
      border: 1px solid var(--white);
}

#dateInputGroup .stv-radio-buttons-wrapper .buttonRadio:first-child{
  border-bottom-left-radius: 40px ;
  border-top-left-radius: 40px;
    }

    #dateInputGroup  .stv-radio-buttons-wrapper .buttonRadio:last-child{
      border-bottom-right-radius: 40px ;
      border-top-right-radius: 40px;
        }

        #dateInputGroup  .stv-radio-button + label {
          font-size: 15px;
          color: var(--white);
      padding: .5em 1em;
      cursor: pointer;
    }

    #dateInputGroup .stv-radio-button:checked + label {
      background: -webkit-linear-gradient(90deg, #E1F7F5 0%, #FFFFFF 100%);
      color: var(--black);
      font-weight: bolder;
    }

    #dateInputGroup .buttonRadio:first-child  .stv-radio-button:checked + label{
      border-bottom-left-radius: 39px ;
      border-top-left-radius: 39px;
  }


  #dateInputGroup .buttonRadio:last-child  .stv-radio-button:checked + label{
    border-bottom-right-radius: 39px ;
    border-top-right-radius: 39px;
}


#DatePicker .e-calendar .e-content td.e-today span.e-day {
  border-color:  var(--azure);
  color: var(--black);
  border-radius: 5px;
}
#DatePicker .e-calendar .e-content td.e-today:hover span.e-day {
  border-color:  var(--azure);
  color: var(--black);
  border-radius: 5px;
}
#DatePicker .e-btn.e-today.e-flat.e-primary.e-css {
    background-color:  var( --dark-grey);
    border-radius: 5px;
    color: var(--white);
}
