.borderRightGrey{
    border-right: 3px solid var(--light-grey);
}
.borderbottom-max-md{  border-bottom: none;
}
@media screen and (max-width: 1200px) {
    .borderRightGrey{
        border-right: none;
    }
    .borderbottom-max-md{
        border-bottom: 3px solid var(--light-grey);
    }
  }