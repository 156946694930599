/*  CHART DIMENSION */

.AngleCharts {
    width: fit-content;
    position: relative;
}

.AngleCharts .chartDimension {
    display: flex;
    overflow: hidden;
    width: 335px;
    place-content: center;
    height: 250px;
    align-items: center;
}

.AngleCharts .labelTotalMyCashDeskCharts {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
}

.AngleCharts .labelTotalMyCashDeskCharts .titleAngleLabel {
    max-width: 150px;
    text-align: center;
}

/*  CHART DIMENSION END*/

/* TOOLTIP */

#CardMyCashDesk #AngleChartsphysical_tooltip_text, #CardMySite #AngleChartsonline_tooltip_text {
    font-family: "Lato" !important;
    font-size: 14px !important;
}

#CardMyCashDesk .e-tooltip g path, #CardMySite .e-tooltip g path {
    opacity: 1;
}