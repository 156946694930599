#myAccountDropdown {
    width: fit-content;
}

#myAccountDropdown button {
    border: none;
    background-color: transparent;
    padding-right: 0;
    padding-bottom: 0;
}

#myAccountDropdown .iconUser {
    display: flex;
    height: 22px;
    width: 22px;
    padding-bottom: 2px;
    background-image: url('/src/img/IconBlackUser.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

#myAccountDropdown .iconUser:hover, #myAccountDropdown.dropdown.show .iconUser {
    background-image: url('/src/img/IconGradUser.png');
}

#myAccountDropdown .dropdown-menu {
    width: max-content;
    right: 0 !important;
    border-radius: 15px;
    border: none;
    box-shadow: 0 5px 10px #00000033;
    padding-left: 8px;
    padding-right: 8px;
}

#myAccountDropdown .btn-check:focus+.btn, #myAccountDropdown .btn:focus {
    outline: 0;
    box-shadow: none;
}

#myAccountDropdown .dropdown-toggle::after {
    display: none
}

#myAccountDropdown .border-bottom-grey {
    border-bottom: 3px solid var(--medium-grey-op50);
    ;
}

#myAccountDropdown .border-top-grey {
    border-top: 1px solid var(--medium-grey);
}

#myAccountDropdown .dropdown-item.active, #myAccountDropdown .dropdown-item:active {
    background: linear-gradient(to left, #18E677, #2BE090, #3EDAA9, #51D4C2, #63CEDB, #76C8F4, #85B8FE, #91A0FE, #9D88FE, #A970FD, #B458FD);
}

#myAccountDropdown .dropdown-item.active p, #myAccountDropdown .dropdown-item:active p {
    color: var(--white);
}

#myAccountDropdown .iconAccount, #myAccountDropdown .iconConditionsofsales, #myAccountDropdown .iconPrivacy,#myAccountDropdown .iconLogout {
    display: flex;
    height: 22px;
    width: 22px;
    padding-bottom: 2px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

#myAccountDropdown .iconAccount {
    background-image: url('/src/img/IconGradAccount.png');
}

#myAccountDropdown .iconConditionsofsales {
    background-image: url('/src/img/IconGradBag.png');
}
#myAccountDropdown .iconPrivacy {
    background-image: url('/src/img/IconGradPrivacy.png');
}

#myAccountDropdown .iconLogout {
    background-image: url('/src/img/IconBlackLogout.png');
  
}

#myAccountDropdown .dropdown-item:active .iconAccount {
    background-image: url('/src/img/IconWhiteUserSettings.png');
}

#myAccountDropdown .dropdown-item:active .iconConditionsofsales {
    background-image: url('/src/img/IconWhiteConditionofsales.png');
}
#myAccountDropdown .dropdown-item:active .iconPrivacy {
    background-image: url('/src/img/IconWhitePrivacy.png');
}

#myAccountDropdown .dropdown-item:active .iconLogout {
    background-image: url('/src/img/IconWhiteLogOut.png');
  
}