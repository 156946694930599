@media screen and (max-width: 1280px) {
  #table-sub-group.it td:nth-of-type(1):before {
    content: "Nome";
  }
  #table-sub-group.it td:nth-of-type(2):before {
    content: "Fatturato Fisico";
  }
  #table-sub-group.it td:nth-of-type(3):before {
    content: "Tasso di Conversione Fisico";
  }
  #table-sub-group.it td:nth-of-type(4):before {
    content: "Guadagno / Presenze Fisico";
  }
  #table-sub-group.it td:nth-of-type(5):before {
    content: "Presenze";
  }
  #table-sub-group.it td:nth-of-type(6):before {
    content: "Fatturato Online";
  }
  #table-sub-group.it td:nth-of-type(7):before {
    content: "Tasso di Conversione Online";
  }
  #table-sub-group.it td:nth-of-type(8):before {
    content: "Guadagno / Visite Online";
  }

  #table-sub-group.fr td:nth-of-type(1):before {
    content: "Nom";
  }
  #table-sub-group.fr td:nth-of-type(2):before {
    content: "Chiffre d'affaires Magasins";
  }
  #table-sub-group.fr td:nth-of-type(3):before {
    content: "Taux de conversion Magasins";
  }
  #table-sub-group.fr td:nth-of-type(4):before {
    content: "Gain / Visite Magasins";
  }
  #table-sub-group.fr td:nth-of-type(5):before {
    content: "Présence";
  }
  #table-sub-group.fr td:nth-of-type(6):before {
    content: "Chiffre d'affaires en ligne";
  }
  #table-sub-group.fr td:nth-of-type(7):before {
    content: "Taux de conversion en ligne";
  }
  #table-sub-group.fr td:nth-of-type(8):before {
    content: "Gain / visite en ligne";
  }
}