
#pieChart {
    overflow: hidden;
    place-content: center;
}
    .pieChart  rect#pieCharttotalTransactions_border{
        fill: transparent;
    }

/* TOOLTIP  */

 .pieChart  #pieCharttotalTransactions_tooltip_svg text{
    font-family: 'Lato' !important;
    font-size:14px !important;
    }

    .pieChart  #pieCharttotalTurnover_tooltip_svg text{
        font-family: 'Lato' !important;
        font-size:14px !important;
        }

.pieChart .e-tooltip g path,#CardMySite .e-tooltip g path{
    opacity: 1;
    
}