/* THE SAME CSS IN datepicker.css for the navbar*/
/* PICK DATE */
#formNewTargetcalendar{
  text-transform: capitalize;
}
#datePicker span.e-input-group.e-control-wrapper.e-date-wrapper {
    display: flex;
  }
  
  .e-datepicker {
    padding: 0 15px;
    background: transparent;
    border: solid 1px #fff;
    border-right: none;
    border-radius: 50px 0 0 50px;
  }
  
  #datePicker span.e-input-group-icon.e-date-icon.e-icons {
    margin: 0;
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    padding: 2px;
    border-radius: 0 40px 40px 0;
    border: 1px solid var(--white);
    border-left: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  #datePicker .datepicker-control-section span.e-input-group.e-control-wrapper.e-date-wrapper.e-valid-input {
    height: 35px;
  }
  
  #datePicker input#calendar {
    background-color: transparent;
    color: var(--white);
    text-transform: capitalize;
    padding: 8px;
    border: 1px solid var(--white);
    border-right: none;
    border-radius: 40px 0 0 40px;
  }
  
  /* Calendar Style */
  
  #datePicker .e-calendar .e-content td.e-today.e-selected span.e-day, #datePicker .e-calendar .e-content .e-selected span.e-day {
    background: linear-gradient(215deg, #18E677, #2BE090, #3EDAA9, #51D4C2, #63CEDB, #76C8F4, #85B8FE, #91A0FE, #9D88FE, #A970FD, #B458FD) !important;
    border-color: black !important;
    color: var(--white) !important;
    border-radius: 5px;
  }
  
  /*hide default Icon and show the custom */
  
  #datePicker .e-input-group-icon.e-date-icon::before, #DatePicker .e-control-wrapper .e-input-group-icon.e-date-icon::before {
    width: 29px;
    content: "";
    background-image: url(/src/img/IconLightCalendar.png);
    height: 29px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  
  #datePicker .e-calendar .e-content td.e-focused-date.e-today span.e-day {
    border-color: var(--azure);
    color: var(--white);
    border-radius: 5px;
  }
  
  /*CALENDAR OPEN */
  
  .GradDatePicker.e-datepicker.e-popup-wrapper {
    border-radius: 20px;
    background: linear-gradient(215deg, #4CDEB1, #70B3ED, #B65AFD);
    padding: 2px 2px 3px 2px;
    box-shadow: none;
  }
  
  .GradDatePicker.e-datepicker .e-calendar {
    border-radius: 20px;
    background-color: var(--white);
    box-shadow: none;
  }
  
  .GradDatePicker.e-datepicker.e-popup-wrapper .e-header {
    border-radius: 0;
    border-bottom: 1px solid var(--light-grey);
  }
  
  .e-calendar .e-header .e-title {
    color: var(--black);
    font-weight: 600;
  }
  
  .GradDatePicker .e-calendar th, .GradDatePicker .e-bigger.e-small .e-calendar th {
    color: var(--black);
    font-weight: 600;
  }
  
  .GradDatePicker .e-calendar .e-footer-container {
    place-content: center;
  }
  
  .GradDatePicker .e-calendar .e-footer-container .e-btn.e-flat.e-primary {
    padding: 8px 38px;
    font-weight: 600;
    text-transform: capitalize;
    color: var(--white);
    border-radius: 50px;
    background: linear-gradient(215deg, #4CDEB1, #70B3ED, #B65AFD);
    font-size: 16px;
  }
  
  .GradDatePicker .e-calendar .e-footer-container .e-btn.e-flat.e-primary:hover {
    opacity: .7;
  }
  
  .e-calendar .e-content.e-year td>span.e-day, .e-calendar .e-content.e-decade td>span.e-day, .e-bigger.e-small .e-calendar .e-content.e-year td>span.e-day, .e-bigger.e-small .e-calendar .e-content.e-decade td>span.e-day {
    font-weight: 600;
    border-radius: 10px;
    background-color: var(--light-grey);
  }
  
  .GradDatePicker .e-calendar .e-content td:hover span.e-day, .GradDatePicker .e-bigger.e-small .e-calendar .e-content td:hover span.e-day {
    border-radius: 10px;
    color: var(--white);
    background-color: var(--medium-grey);
  }
  
  .GradDatePicker .e-calendar .e-content td.e-selected.e-focused-date span.e-day {
    border-radius: 10px;
    background: linear-gradient(to left, #B65AFD 0%, #70B3ED 50%, #4CDEB1 100%);
  }
  
  .GradDatePicker .e-calendar .e-content td.e-selected span.e-day {
    border-radius: 10px;
    background: linear-gradient(to left, #B65AFD 0%, #70B3ED 50%, #4CDEB1 100%);
  }
  
  .GradDatePicker .e-calendar, .e-bigger.e-small .e-calendar {
    max-width: 300px;
    min-width: 295px;
  }

  .e-calendar .e-content td.e-today.e-selected span.e-day{
    box-shadow: none;
  }
  .GradDatePicker .e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today span.e-day {
    background-color: white !important;
    border: none !important;
    font-weight: 800 !important;
    color: var(--white) !important;
  }

 .GradDatePicker .e-bigger.e-small .e-calendar .e-content td.e-today span.e-day{
    color: var(--black)!important;
  }
  
   .GradDatePicker .e-calendar .e-content td.e-today span.e-day{
    background-color: white !important;
    border: none !important;
    font-weight: 800 !important;
    color: var(--black);
  } 
  .GradDatePicker .e-calendar .e-content td.e-selected.e-focused-date span.e-day{
    color: var(--white) !important;
  }


  .GradDatePicker .e-calendar .e-content td.e-today:hover span.e-day {
    background-color: var(--medium-grey) !important;
    color: var(--white) !important;
  }
  .GradDatePicker .e-calendar .e-content td.e-today:hover span.e-day {
    background-color: var(--medium-grey) !important;
    color: var(--white) !important;
  }
  
  /* MOBILE HEADER */
  
  .e-datepick-mob-popup-wrap .e-model-header {
    background: -webkit-linear-gradient(180deg, #B65AFD 0%, #70B3ED 50%, #4CDEB1 100%);
  }
  
  .e-calendar .e-content.e-year td, .e-calendar .e-content.e-decade td, .e-bigger.e-small .e-calendar .e-content.e-year td, .e-bigger.e-small .e-calendar .e-content.e-decade td {
    padding-left: 3px;
    padding-right: 3px;
  }
  
  .e-calendar .e-content.e-year td>span.e-day, .e-calendar .e-content.e-decade td>span.e-day, .e-bigger.e-small .e-calendar .e-content.e-year td>span.e-day, .e-bigger.e-small .e-calendar .e-content.e-decade td>span.e-day {
    width: 100%;
  }

  .disabledTdDatePicker{
    pointer-events: none;
  
    color: var(--light-grey)!important;
  }
  td.disabledTdDatePicker:hover span{
    color: var(--white)!important;
    background-color: var(--light-grey)!important;
  }
  .disabledTdDatePicker span{
    pointer-events: none;
    color: var(--light-grey) !important;
  }

  .GradDatePicker .e-device .e-model-header {
    display:none;
  }