.modal-backdrop{
    background-image:  linear-gradient(180deg, #2E8066 0%, #50789B 50%, #613286 100%);
}
#mainSidebar.offcanvas-start{
    width: 450px;
    padding: 16px 1px;
}

#mainSidebar.e-treeview .e-ul{
    padding: 0;
    border: none;
    border-color:transparent;
}
.e-treeview:not(.e-fullrow-wrap) .e-list-item.e-active > .e-text-content{

    border: none !important;
    border-color:transparent !important;

}






/* SCROLLBAR */
/* width */
#mainSidebar.offcanvas-start ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  
  }
  
  /* Track */
  #mainSidebar.offcanvas-start ::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px;
  }
   
  /* Handle */
  #mainSidebar.offcanvas-start ::-webkit-scrollbar-thumb {
    background: rgb(199, 199, 199); 
    border-radius: 10px;
  }



div#mainSidebar {
    border-radius: 0 20px 20px 0;
    box-shadow: 10px 0 60px rgb(0 0 0 / 10%);
}

#mainSidebar .border-bottom-grey{
    border-bottom: 3px solid var(--medium-grey-op50);
}

#mainSidebar .accordion-button:focus{
    border-color: none;
    outline: 0;
    box-shadow:none;
}

#mainSidebar .accordion-button:not(.collapsed){
    color: inherit;
    background-color:transparent;
    box-shadow: none;
}

#mainSidebar .accordion-item{
    border-radius: 0;
    border: none;  
    border-bottom: 3px solid var(--medium-grey-op50);
}

#mainSidebar button.accordion-button.collapsed, #mainSidebar button.accordion-button{
    padding:  0 ;
}

#mainSidebar .accordion{
padding:  0 1rem;
}

.accordion-button:not(.collapsed)::after{
    background-repeat: no-repeat;
    background-size: contain;
    width: 15px;
    height: 15px;
    background-image: url("/src/img/IconGradArrowRight.png");
    transform: rotate(
90deg
);
}

.accordion-button::after{
    background-repeat: no-repeat;
    background-size: contain;
    width: 15px;
    height: 15px;
    background-image: url("/src/img/IconBlackRightArrow.png");
}

#mainSidebar .accordion-body{
    padding: 0;
}

/* TREEVIEW TEMPLATE */
#treeviewSidebarTemplate .circleName {
    border-radius: 100%;
    background-color: var(--medium-grey);
    height: 28px;
    width: 28px;
    min-width: 28px;
    overflow: hidden;
}

#treeviewSidebarTemplate .storeIcon {
background-image: url("/src/img/IconGreyStore.png");
background-repeat: no-repeat;
height: 28px;
min-width: 22px;
    background-repeat: none;
    background-size: contain;
    background-position: center;
}


.e-treeview .e-list-item.e-active > .e-text-content .e-list-text #treeviewSidebarTemplate .circleName,
#treeviewSidebarTemplate .active .circleName{
    background: linear-gradient(180deg, #B65AFD 0%, #70B3ED 50%, #4CDEB1 100%);
}

.e-treeview .e-list-item.e-active > .e-text-content .e-list-text #treeviewSidebarTemplate .storeIcon,
#treeviewSidebarTemplate .active .storeIcon{
    background-image: url("/src/img/IconGradStore.png");
}


.e-treeview .e-list-item.e-active > .e-text-content .e-list-text #treeviewSidebarTemplate .txtName,#treeviewSidebarTemplate .active .txtName{
   font-weight: bolder;

}


#treeviewSidebarTemplate .e-treeview .e-text-content{
    padding: 0;
  }