.stickySideBar{
  position: sticky;
  top: 130px;
  z-index: 9;
  height: 100%;
  max-height: calc(100vh - 150px);
}


@media screen and (max-width: 768px) {
  .stickySideBar{
    top: 0;
      height:fit-content;
      position:relative;
  }
}

#sideBarUser a:hover{
  color: var(--black);
  font-weight: bolder;
}
/* SHOW/HIDE DSEKTOP-MOBILE */
.mobileSidebarUser { 
  display: none; 
}
.desktopSidebarUser {
   display: flex;
   }
  
  @media (max-width: 768px) {
    .mobileSidebarUser { 
      display: flex; 
    }
    .desktopSidebarUser { 
      display: none; 
    }
  }


.borderBottomGrey{
    border-bottom: 3px solid var(--light-grey);
}

/* SIDEBAR MOBILE STYLE */
#sideBarUser button#dropdown-mobile-user{
color:var(--black);
width: 100%;
border: none;
border-bottom: 2px solid var(--light-grey);
padding: 10px 5px;
font-weight: font-weight-bold;
background-color:transparent; 
display: flex;
justify-content: space-between;
align-items: center;
padding: 5px;
} 

#sideBarUser  .dropdown-item:focus,
#sideBarUser .dropdown-item:hover,
#sideBarUser  .dropdown-item.active,
#sideBarUser .dropdown-item:active{
  background: -webkit-linear-gradient(180deg, #18E677 0%, #2BE090 10%,#3EDAA9 20%,#51D4C2 30%,#63CEDB 40%,#76C8F4 50%,#85B8FE 60%,#91A0FE 70%,#9D88FE 80%,#A970FD 90%,#B458FD 100%);
 color:var(--white);
 width: 100%;
}
#sideBarUser  .dropdown-item:focus{ 
  font-weight: font-weight-bold;
}
#sideBarUser  .dropdown-item:hover{ 
  font-weight: 500;
}
#sideBarUser .dropdown-menu.show {
  width: 100%;
}
.dropdown-toggle::after{
  color:var(--dark-grey);
}

#sideBarUser .dropdown-item{ 
display: flex;
align-items: baseline;
justify-content: space-between;
}




